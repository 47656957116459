/*----------------------------------------
Fade In
----------------------------------------*/
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)


/*---------- Main ----------*/
const onAnimate = () => {
  ScrollTrigger.batch('.js-fadeIn', {
    onEnter: elements => {
      gsap.fromTo(elements, {
        autoAlpha: 0,
        y: 32
      }, {
        autoAlpha: 1,
        y: 0,
        duration: 1.2,
        stagger: .1
      })
    },
    start: '160px bottom',
    once: true
  })
}


/*---------- Exports ----------*/
export {onAnimate as fadeIn}