/*----------------------------------------
Navigation
----------------------------------------*/
import gsap from 'gsap'


/*---------- Main ----------*/
const body = document.body
const header = document.querySelector('.header')
const headerSpNav = document.querySelector('.header__sp-nav')
const tl = gsap.timeline()

const onToggle = () => {
  if (!body.classList.contains('is-opened')) {
    onOpen()
  } else {
    onClose()
  }
}

const onOpen = () => {
  tl.to(headerSpNav, .4, {
    autoAlpha: 1
  })
  body.classList.add('is-opened')
}

const onClose = () => {
  tl.to(headerSpNav, .4, {
    autoAlpha: 0
  })
  body.classList.remove('is-opened')
}


/*---------- Scrolled ----------*/
const onScroll = () => {
  const scrollY = window.pageYOffset
  const windowHeight = window.innerHeight
  const headerHeight = header.clientHeight
  const hero = document.querySelector('.hero')
  const heroClientRect = hero.getBoundingClientRect()
  const heroY = scrollY + heroClientRect.bottom - headerHeight
  const footer = document.querySelector('.footer')
  const cta = document.querySelector('.cta')
  const footerPosi = footer.getBoundingClientRect().top + scrollY

  if(scrollY > heroY) {
    header.classList.add('is-scrolled')
  } else {
    header.classList.remove('is-scrolled')
  }


  if(cta) {
    if (scrollY < heroY / 3 || scrollY > footerPosi - windowHeight) {
    cta.classList.remove('is-scrolled')
    } else {
      cta.classList.add('is-scrolled')
    }
  }
}


/*---------- Exports ----------*/
export { onToggle as toggleNavigation, onScroll as scrollNavigation }