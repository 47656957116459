/*----------------------------------------
App
----------------------------------------*/
import Swiper from 'swiper'
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules'

Swiper.use([Autoplay, EffectFade, Navigation, Pagination])

const swiper1 = new Swiper('.js-hero', {
  autoplay: {
    delay: 5000,
  },
  allowTouchMove: false,
  loop: true,
  effect: 'fade',
  speed: 2000
})

const swiper2 = new Swiper('.js-slider', {
  autoplay: false,
  loop: true,
  slidesPerView: 1,
  spaceBetween: 0,
  pagination: {
    el: '.js-slider-fraction',
    type: 'custom',
    renderCustom: function (swiper, current, total) {
      return current + '/' + total
    }
  },
  navigation: {
    nextEl: '.js-slider-next',
    prevEl: '.js-slider-prev'
  }
})


/*---------- Exports ----------*/
export { swiper1 as hero, swiper2 as slider }