/*----------------------------------------
Smooth Scroll
----------------------------------------*/
const startScroll = () => {
  const Ease = {
    easeInOut: function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1}
  }
  const duration = 800
  const anchorLinks = [].slice.call(document.querySelectorAll('a[href^="#"]'))

  anchorLinks.forEach( function(anchorLink) {
    anchorLink.addEventListener('click', function(e) {
      const href = e.currentTarget.getAttribute('href')
      const currentPostion = document.documentElement.scrollTop || document.body.scrollTop
      const target = document.getElementById(href.replace('#', ''))
      if (target) {
        e.preventDefault()
        e.stopPropagation()

        var targetPosition = window.pageYOffset + target.getBoundingClientRect().top - 79
        var startTime = performance.now()
        var loop = function (nowTime) {
          var time = nowTime - startTime
          var normalizedTime = time / duration

          if (normalizedTime < 1) {
            window.scrollTo(0, currentPostion + ((targetPosition - currentPostion) * Ease.easeInOut(normalizedTime)))
            requestAnimationFrame(loop)
          } else {
            window.scrollTo(0, targetPosition)
          }
        }
        requestAnimationFrame(loop)
      }
    })
  })
}


/*---------- Exports ----------*/
export { startScroll as smoothScroll }