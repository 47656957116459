/*----------------------------------------
Accordion
----------------------------------------*/
import gsap from 'gsap'

const toggleAccordion = () => {
  const jsAccordion = document.getElementsByClassName('js-accordion')

  for (let i = 0; i < jsAccordion.length; i++) {
    jsAccordion[i].addEventListener('click', () => {
      const accordionElement = jsAccordion[i].nextElementSibling
      const result = jsAccordion[i].classList.contains('is-opened')

      if(result) {
        jsAccordion[i].classList.remove('is-opened')

        gsap.to(accordionElement, {
          autoAlpha: 0,
          height: 0,
          duration: .4,
          ease: 'power2.inOut'
        })
      } else {
        jsAccordion[i].classList.add('is-opened')

        gsap.to(accordionElement, {
          autoAlpha: 1,
          height: 'auto',
          duration: .4,
          ease: 'power2.inOut'
        })
      }
    })
  }
}


/*---------- Exports ----------*/
export { toggleAccordion as accordion }