/*----------------------------------------
Animate Text
----------------------------------------*/
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)


/*---------- Title ----------*/
const wrapper = document.querySelector('.wrapper')
const jsHorizontals = document.getElementsByClassName('js-horizontal')

const onAnimate = () => {
  for (let i = 0; i < jsHorizontals.length; i++) { // Horizontal
    ScrollTrigger.create({
      trigger: jsHorizontals[i],
      start: 'top bottom',
      end: 'bottom top',
      onUpdate: self => {
        const x = self.progress * -50

        jsHorizontals[i].style.transform = 'translate('+ x +'%, 0)'
      }
    });
  }
}


/*---------- Exports ----------*/
export { onAnimate as animateText }