/*----------------------------------------
Movie
----------------------------------------*/
const script = document.createElement('script')
const firstScript = document.getElementsByTagName('script')[0]
const jsPlay = document.getElementsByClassName('js-play')

const onPlay = () => {
  script.src = "https://www.youtube.com/iframe_api"
  firstScript.parentNode.insertBefore(script, firstScript)

  let ytPlayer = []

  for (let i = 0; i < jsPlay.length; i++) {
    jsPlay[i].addEventListener('click', () => {
      ytPlayer = new YT.Player(jsPlay[i].lastElementChild, {
        controls: 0,
        disablekb: 0,
        iv_load_policy: 3,
        modestbranding: 1,
        rel: 0,
        showinfo: 0,
        videoId: jsPlay[i].dataset.id,
        playerVars: {
          autoplay: 1,
          playsinline: 1
        }
      })
    }, false)
  }
}



/*---------- Exports ----------*/
export { onPlay as movie }