/*----------------------------------------
App
----------------------------------------*/
import { accordion } from './utils/accordion'
import { animateText } from './utils/animate-text'
import { fadeIn } from './utils/fade-in'
import { form } from './utils/form'
import { movie } from './utils/movie'
import { toggleNavigation, scrollNavigation } from './utils/navigation'
import { hero, slider } from './utils/slider'
import { smoothScroll } from './utils/smooth-scroll'


/*---------- Main ----------*/
const jsNavButton = document.querySelector('.js-navButton')

jsNavButton.addEventListener('click', toggleNavigation)
window.addEventListener('scroll', scrollNavigation)
window.onload = () => {
  animateText()
  accordion()
  fadeIn()
  form()
  movie()
  smoothScroll()
}