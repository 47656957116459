/*----------------------------------------
Form
----------------------------------------*/
const onCheckEmail = (str) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
  return re.test(str)
}

function onCheckForm() {
  const required = this.querySelectorAll('[aria-required="true"]')
  let requiredArray = {}
  const email = this.querySelector('input[type="email"]')
  const checkbox = this.querySelector('input[type="checkbox"]')
  const submit = this.querySelector('input[type="submit"]').parentNode
  const emailStr = email.value.trim()

  required.forEach((el) => {
    if (el.value === '') {
      requiredArray[el.name] = false
    } else if (requiredArray[el.name] === false) {
      delete requiredArray[el.name]
    }

    if (Object.keys(requiredArray).length === 0 && onCheckEmail(emailStr) && checkbox.checked === true) {
      submit.classList.remove('is-disabled')
    } else {
      submit.classList.add('is-disabled')
    }
  })
}

const setForm = () => {
  const form = document.querySelector('form')

  if (form) {
    const submit = form.querySelector('input[type="submit"]').parentNode
    submit.classList.add('is-disabled')

    form.addEventListener('change', onCheckForm)
    form.addEventListener('input', onCheckForm)
  }
}


/*---------- Exports ----------*/
export {setForm as form}